<template>
	<div class="home">
		<span>
			{{ $t('home.intro') }}<br>
			{{ $t('home.chooseNavigation') }}
		</span><br><br><br><br>
		<span id="Welcome">
			{{ $t('home.welcome') }}
		</span><br>
		<span id="Equipo">
			{{ $t('home.equipo') }}
		</span>
	</div>
</template>

<script>
export default {
	name: 'Home'
};
</script>

<style lang="scss">
#Welcome {
    margin-left: 10%;
	color: #c00000;
	font-size: 2rem;
}
#Equipo {
	margin-left: 10%;
	color: #c00000;
	font-size: 9rem;
}
</style>
