import { get, post } from '@/util/request.js';
import { createPatch } from 'rfc6902';
import * as ProductRepo from './product.js';

export const searchWindow = 250;
export const MAX_SEARCH_RESULTS = 9000;
let ecListPromise;

function getEntityclassList() {
	return get('eav/entityclass/list');
}

export async function getEntityclassByExternalId(extId) {
	if (!ecListPromise) {
		ecListPromise = getEntityclassList();
	}
	let list = await ecListPromise;
	let ec = list.find(e => e.externalId === extId);
	if (!ec) throw new Error(`ec not found: ${extId}`);
	return ec;
}

export function getAttributesetByExternalId(extId) {
	return get('eav/attribute/getbyexternalid', extId);
}

export function getDomain() {
	return get('eav/domain/getbyurl');
}

export async function getEntityById(id) {
	let list = await getEntitiesById([id]);
	return list[0];
}

export function getEntityFromEav(params) {
	return get('eav/entity/get', params);
}

export function getEntitiesById(ids) {
	if (!ids || !ids.length || !ids.filter(id => !!id).length) return [];
	ids = ids.filter(id => !!id);
	return post('search/search/ids', { ids });
}

export function genericEntityUpdate(oldEntity, newData, changeset, supe) {
	if (!oldEntity) {
		return null;
	}

	const patch = createPatch(oldEntity.data, newData);
	if (patch.length === 0) {
		return null;
	}
	let payload = {
		id: oldEntity.id,
		rev: oldEntity.rev,
		patch
	};
	if (changeset && changeset !== oldEntity.attributeset) {
		payload.changeset = changeset;
	}
	return post('eav/entity/updatefrompatch', payload, null, supe);
}

export function genericEntityCreate(entityclass, attributeset, data) {
	return post('eav/entity/create', {
		entityclass,
		attributeset,
		data
	});
}

export function genericEntityDelete({ id }) {
	return post('eav/entity/delete', { id });
}

export function getTimeline(entityId, start, limit) {
	return get('eav/entity/history', {
		id: entityId,
		start,
		limit
	});
}

export function search(entityclass, filter, pagination, sorting) {
	return _search(entityclass, filter, 'post', 'search/search/execute', pagination, sorting);
}

export async function _search(entityclass, filter, requestType, endpoint, pagination, sorting, onlyIds) {
	let result = [];
	let searchRequest = {
		pagination: {
			start: 0,
			limit: searchWindow
		}
	};
	if (entityclass) searchRequest.entityClass = entityclass;
	if (filter) searchRequest.attributeFilter = filter;
	if (pagination) searchRequest.pagination = pagination;
	if (sorting) searchRequest.sorting = sorting;
	if (onlyIds) searchRequest.onlyIds = true;

	let temp;
	do {
		if (!requestType || requestType === 'get') {
			temp = await get(endpoint, searchRequest);
		} else {
			temp = await post(endpoint, searchRequest);
		}
		result = result.concat(temp.list ? temp.list : temp);

		if (!pagination) {
			searchRequest.pagination.start += searchWindow;
		}
	} while (!pagination && temp && temp.length >= searchWindow && temp.length <= MAX_SEARCH_RESULTS);

	return result;
}

export async function _searchCursor(entityclass, filter, requestType, endpoint, sorting, onlyIds) {
	let result = [];
	let searchRequest = {};
	if (endpoint.substr(0, 4) === 'bml/') {
		searchRequest.pagination = { limit: searchWindow };
		searchRequest.useCursor = true;
	} else {
		searchRequest.limit = searchWindow;
	}
	if (entityclass) {
		searchRequest.entityClass = entityclass;
	}
	if (filter) {
		searchRequest.attributeFilter = filter;
	}
	if (sorting) {
		searchRequest.sorting = sorting;
	}
	if (onlyIds) {
		searchRequest.onlyIds = true;
	}

	let temp;
	let lastCursor;
	do {
		if (lastCursor) {
			searchRequest.cursor = [ lastCursor ];
		}

		if (!requestType || requestType === 'get') {
			temp = await get(endpoint, searchRequest);
		} else {
			temp = await post(endpoint, searchRequest);
		}

		if (temp.cursor && temp.cursor.length > 0) {
			lastCursor = temp.cursor[0];
		}

		result = result.concat(temp.list);
	} while (lastCursor && temp && temp.list.length >= searchWindow);

	return result;
}

export async function* searchCursorWithGenerator(entityclass, filter, requestType, endpoint, sorting, idsOnly) {
	let searchRequest = {};
	if (endpoint.substr(0, 4) === 'bml/') {
		searchRequest.pagination = { limit: searchWindow };
		searchRequest.useCursor = true;
	} else {
		searchRequest.limit = searchWindow;
	}
	if (entityclass) {
		searchRequest.entityClass = entityclass;
	}
	if (filter) {
		searchRequest.attributeFilter = filter;
	}
	if (sorting) {
		searchRequest.sorting = sorting;
	}
	if (idsOnly) {
		searchRequest.idsOnly = true;
	}
	let temp;
	let lastCursor;
	do {
		if (lastCursor) {
			searchRequest.cursor = [ lastCursor ];
		}
		if (!requestType || requestType === 'get') {
			temp = await get(endpoint, searchRequest);
		} else {
			temp = await post(endpoint, searchRequest);
		}
		if (temp.cursor && temp.cursor.length > 0) {
			lastCursor = temp.cursor[0];
		}
		if (endpoint.includes('product')) ProductRepo.clearProductCache();
		for (const item of temp.list) {
			if (endpoint.includes('product')) ProductRepo.addToProductCache(item);	//in case it is then saved
			yield item;
		}
	} while (lastCursor && temp && temp.list.length >= searchWindow);
}


export async function countEntries(entityClass, filter) {
	const endpoint = 'search/search/count';
	let searchRequest = {
		entityClass
	};
	if (filter) searchRequest.attributeFilter = filter;
	let result = await post(endpoint, searchRequest);
	return result;
}
