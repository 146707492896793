import { get, post } from '@/util/request.js';
import { createPatch } from 'rfc6902';
import EventHub from '@/util/eventbus.js';
import { disablePresence } from '@/util/env.js';
import { doNotMergePaths, listOfPathsForDeepLinking } from '@/store/modules/product';
import { doNotUnlinkNewLists } from '@/util/component-helper';
// import { createNotesOnProduct } from '@/util/notes-watcher';

const cache = new Map();

export function clearProductCache() {
	console.debug('clearing items from product cache', cache.size);
	cache.clear();
}

export function addToProductCache(p) {
	cache.set(p.id, p);
}

export async function loadProductWithCache(productId) {
	let entity;
	if (cache.has(productId)) {
		entity = cache.get(productId);
	} else {
		entity = await loadProduct(productId);
	}
	return JSON.parse(JSON.stringify(entity));
}

export async function loadProduct(productId, rev) {
	let payload = { 'id': productId };
	if (rev) {
		payload.rev = rev;
	}

	const entity = await get('bml/product/load', payload);
	cache.set(entity.id, entity);
	return entity;
}

async function saveProduct(id, rev, patch, attempt, oldSet, changeset) {
	// console.log('patch', patch);
	// return cache.get(id);
	try {
		let payload = { id, rev, patch };
		if (changeset && changeset !== oldSet) {
			payload.changeset = changeset;
		}
		let entity = await post('bml/product/save', payload, null, true);
		if (entity) {
			cache.set(entity.id, entity);
		}
		return entity;
	} catch (e) {
		if (e.message.match(/1560950549/) === null) {	//check for error code targeting rev
			throw e;
		}
		let newEnt = await loadProduct(id);
		if (attempt >= 2) {
			throw new Error('unable to save after 3 attempts, giving up.');
		}
		return saveProduct(id, newEnt.rev, patch, attempt+1, oldSet, changeset);
	}
}

export async function saveProductWrap(productId, data, changeset, withPatch) {
	let oldEntity = cache.get(productId);
	if (!oldEntity) oldEntity = await loadProductWithCache(productId);
	// console.log('create patch for ', JSON.stringify(data, null, 2));
	let patch = createPatch(oldEntity.data, data);
	if (patch.length === 0) {
		return withPatch ? { entity: oldEntity, patch: [] } : null;
	}
	let entity = await saveProduct(oldEntity.id, oldEntity.rev, patch, 0, oldEntity.attributeset, changeset);
	// console.log('nach dem Speichern im repo: ', entity);
	if(withPatch) {
		return { entity, patch };
	}
	return entity;
}

export async function syncListData(productId, listId, newData, changeset) {
	let entity = cache.get(productId);
	if (!entity) {
		console.warn('expected product in cache but got nothing', { productId, listId, newData });
		entity = await loadProduct(productId);
	}
	let data = JSON.parse(JSON.stringify( entity.data ));
	data.listData[listId] = newData;
	await EventHub.emit('beforeProductSaveAllLists', { id: productId, data, savedList: listId });
	let newEntity = await saveProductWrap(productId, data, changeset, true);
	if (!newEntity.entity) {
		newEntity.entity = await loadProduct(productId);
		if (newEntity.entity.rev === entity.rev) return null;
	}
	const savedData = newEntity.entity.data.listData[listId];
	const patch = newEntity.patch;
	return { savedData, patch };
}

export async function setParentId(id, parentProductId, changeset) {
	let oldEntity = await loadProductWithCache(id);
	if (!oldEntity || !oldEntity.data) {
		return false;
	}
	let data = JSON.parse(JSON.stringify(oldEntity.data));

	if (parentProductId && parentProductId !== '') {
		data.parentProductId = parentProductId;
	} else {
		delete data.parentProductId;
	}

	let entity = await saveProductWrap(id, data, changeset);
	return entity;
}

function setNewDerivedProductData(data, derivedProduct, fieldByPath, getDefaultValue) {
	for (let [ listId, listData ] of Object.entries(data.listData)) {
		if (!listData._linked || !Object.keys(listData._linked).length) {
			continue;
		}
		for (let [ fieldName, fieldValue ] of Object.entries(listData._linked)) {
			if (fieldValue !== 'related-product' || doNotMergePaths.includes(fieldName) || listOfPathsForDeepLinking.includes(fieldName)) {
				continue;
			}
			if (derivedProduct.data.listData[listId] && fieldName in derivedProduct.data.listData[listId]) {
				listData[fieldName] = derivedProduct.data.listData[listId][fieldName];
			} else {
				let field = fieldByPath(fieldName);
				listData[fieldName] = getDefaultValue(field.id);
			}
		}
	}
}

export async function setDerivedProductId(id, derivedProductId, changeset, fieldByPath, getDefaultValue) {
	let oldEntity = await loadProductWithCache(id);
	if (!oldEntity || !oldEntity.data) {
		return false;
	}
	let data = JSON.parse(JSON.stringify(oldEntity.data));

	if (derivedProductId) {
		data.derivedProductId = derivedProductId;
	} else {
		for (let listData of Object.values(data.listData)) {
			if (listData._linked) {
				for (let [ entryName, linkedEntry] of Object.entries(listData._linked)) {
					if (linkedEntry === 'related-product') {
						listData._linked[entryName] = 'direct';
					}
				}
			}
		}
		delete data.derivedProductId;
	}
	if (oldEntity.data.derivedProductId && oldEntity.data.derivedProductId !== derivedProductId && derivedProductId) {
		let derivedProduct = await loadProductWithCache(derivedProductId);
		if (derivedProduct) {
			setNewDerivedProductData(data, derivedProduct, fieldByPath, getDefaultValue);
		}
	}

	let entity = await saveProductWrap(id, data, changeset);
	return entity;
}

export async function resetNationalPartnerStatus(id, changeset) {
	const oldEntity = await loadProductWithCache(id);
	if (!oldEntity || !oldEntity.data) {
		return false;
	}
	let data = JSON.parse(JSON.stringify(oldEntity.data));
	delete data.isNationalPartner;

	let entity = await saveProductWrap(id, data, changeset);
	return entity;
}

export async function setListArchived(id, listId, archived, changeset) {
	let oldEntity = cache.get(id);
	let data = JSON.parse(JSON.stringify(oldEntity.data));
	data.listData[listId]._archived = archived;

	let entity = await saveProductWrap(id, data, changeset);
	return entity;
}

export async function searchProducts(filter, pagination, sorting) {
	const endpoint = 'bml/product/search';
	let searchRequest = {};

	if (pagination) {
		searchRequest.pagination = pagination;
	}

	if (sorting) {
		searchRequest.sorting = sorting;
	}

	if (filter) searchRequest.attributeFilter = filter;
	let result = await post(endpoint, searchRequest);
	return result.list;
}

export async function countProducts(filter) {
	const endpoint = 'bml/product/count';
	let searchRequest = {};
	if (filter) searchRequest.attributeFilter = filter;
	let result = await post(endpoint, searchRequest);
	return result;
}

export async function createProduct(data) {
	let entity = await post('bml/product/create', {}, null, true);
	cache.set(entity.id, entity);
	try {
		entity = await saveProductWrap(entity.id, data);
	} catch (e) {
		await deleteProduct(entity.id);
		throw e;
	}
	return entity;
}

export async function deleteProduct(id) {
	let deletedEntity = await post('bml/product/delete', id);
	return deletedEntity.id;
}

export async function getProductTimeline(id) {
	let timeline = await get('bml/product/gettimeline', id);

	return timeline;
}

export async function updatePresenceStatus(id) {
	if (disablePresence) return [];
	const presentUsers = await post('bml/presence/update', id || '', null, false, true, true);
	return presentUsers;
}

const doNotCopyForNewList = [
	'_companies',
	'_components',
	'_documents',
	'_notes',
	'deactivatedBy',
	'deactivatedOn',
	'_active',
	'_checklist',
	'_nameHistory',
	'_responsible',
	'_approval',
	'_invoiceSpecialItems'
];
const doNotLinkInNewList = [
	'_categoryTree',
	'_display',
	'_risklabels',
	'_hobbygardenTree',
	'_preventPublicDisplay'
];
// Adds and persists an list to an given product without changing an working copy.
// This must be done in sync with updating the product state.
export async function addList(productId, listId, baseListId, attributesetId, accountId, fieldsEmptyInList) {
	// fetch the product from the server
	const product = await loadProductWithCache(productId);
	if (!product || !product.data || !product.data.listData) {
		throw new Error('listdata not found');
	}

	// list may already exist. prevent execution.
	if (product.data.listData[listId]) {
		throw new Error('listdata already exists');
	}

	// add the new list.
	const newData = JSON.parse(JSON.stringify(product.data));
	const newList = newData.listData[listId] = {
		activatedOn: {
			dateValue: new Date().toJSON().substr(0, 10)
		},
		activatedBy: accountId
	};

	// unlink declared fields
	if (fieldsEmptyInList && fieldsEmptyInList.length) {
		newList._linked = {};
		for (const field of fieldsEmptyInList) {
			if (!field.emptyInLists || !field.emptyInLists.includes(listId)) continue;
			newList[field.path] = field.defaultValue;
			newList._linked[field.path] = 'direct';
		}
	}

	// unlink component fields
	if (newData.listData[baseListId]._components && (newData.listData[baseListId]._components.base || newData.listData[baseListId]._components.additional)
		&& !doNotUnlinkNewLists.includes(listId)) {
		newList._components = { base: [], additional: [] };
		const unlinkNewComponents = function(newData, baseListId, listId, type) {
			if (newData.listData[baseListId]._components[type] && newData.listData[baseListId]._components[type].length) {
				for (const baseListComponent of newData.listData[baseListId]._components[type]) {
					let baseListSecrecy = baseListComponent.secrecy ? baseListComponent.secrecy.selectValue : null;
					const unlinkedComponent = {
						secrecy: {
							selectValue: baseListSecrecy === 'base-data-responsible' ? 'base-data-responsible' : 'core-partners'
						},
						_id: baseListComponent._id
					};
					newList._components[type].push(unlinkedComponent);
				}
			}
		};
		unlinkNewComponents(newData, baseListId, listId, 'base');
		unlinkNewComponents(newData, baseListId, listId, 'additional');
	}

	let baseList = product.data.listData[baseListId];
	if (baseList) {
		Object.entries(baseList).forEach(([k, v]) => {
			if (doNotCopyForNewList.includes(k) || k in newList) return;
			newList[k] = v;
			if (!doNotLinkInNewList.includes(k)) {
				newList._linked[k] = 'basis';
			}
		});
	}

	// patch the product (e. g. add the new list object).
	let patch = createPatch(product.data, newData);
	if (patch.length === 0) {
		throw new Error('no patch created');
	}

	// send the patch to the server and return an success value.
	const entity = await saveProduct(product.id, product.rev, patch, 0, product.attributeset, attributesetId);
	return entity.data.listData[listId];
}

export function moveList(params) {
	return post('bml/product/movelist', params);
}

export async function aggregateSearch({ attributeFilter, targetField, groupBy, ecName, limit }) {
	const endpoint = 'bml/product/aggregatesearch';
	let result = await post(endpoint, { attributeFilter, targetField, groupBy, ecName, limit });
	return result;
}

EventHub.on('product.cache.clear', clearProductCache);
