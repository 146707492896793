import { get as sendGetRequest, post as sendPostRequest } from '@/util/request.js';
import { getEntityclassByExternalId, getAttributesetByExternalId, getEntityById } from './system.js';
import { base64EncodeFile, base64DecodeFile } from '@/util/document-helper.js';
const sha1 = require('sha1');

let entityClassId, attributeSetId;

async function init() {
	if (!entityClassId) {
		let entityClass = await getEntityclassByExternalId('uploaded');
		entityClassId = entityClass.id;
	}
	if (!attributeSetId) {
		let attributeSet = await getAttributesetByExternalId('uploaded');
		attributeSetId = attributeSet.id;
	}
}

async function encodeFile(fileContent) {
	let encodedFile = await base64EncodeFile(fileContent);
	if (encodedFile.length > 32 * 1024 * 1024) {
		throw new Error('File size cannot be above 32 MB.');
	}
	return encodedFile;
}

export async function createDocument(fileContent, fileName, flags) {
	await init();

	let encodedFile = await encodeFile(fileContent);
	let hashedFile = sha1(encodedFile);

	let documentConfig = {
		entityclass: entityClassId,
		attributeset: attributeSetId,
		data: {
			fileName,
			hash: hashedFile,
			flags
		}
	};

	let documentEntity = await sendPostRequest('eav/entity/create', documentConfig);
	let attachmentResponse;
	try {
		attachmentResponse = await sendPostRequest('eav/entity/setattachment', {
			rev: documentEntity.rev,
			id: documentEntity.id,
			attachment: encodedFile
		});
	} catch (e) {
		await sendPostRequest('eav/entity/delete', { id: documentEntity.id });
		throw new Error(`Upload of attachment failed: ${fileName}.`);
	}
	return attachmentResponse;
}

export async function getDocument(id, fileName) {
	let document = await getEntityById(id);
	let attachment = await sendGetRequest('eav/entity/getattachment', { id });
	if (!attachment) {
		throw new Error(`File with name ${fileName} not found`);
	}
	Object.defineProperty(document, 'file', {
		get() {
			return base64DecodeFile(attachment, fileName);
		}
	});
	return document;
}

export async function findIdentical(file) {
	await init();

	let encodedFile = await base64EncodeFile(file);
	let hashedFile = sha1(encodedFile);

	let searchRequest = {
		entityClass: entityClassId,
		attributeFilter: {
			field: '/data/hash',
			comparison: 'eq',
			value: hashedFile
		},
		sorting: '_score:desc',
		onlyIds: true
	};

	let identicalFileIds = await sendGetRequest('search/search/execute', searchRequest);

	return identicalFileIds.length === 0 ? null : identicalFileIds[0];
}

export async function getDecodedAttachment(id, rev, fileName) {
	let params = { id };
	if (rev) params.rev = rev;
	let attachment = await sendGetRequest('eav/entity/getattachment', params);
	if (!attachment) return null;
	return base64DecodeFile(attachment, fileName);
}

export async function setAttachment(entity, fileContent) {
	await init();
	let encodedFile = await encodeFile(fileContent);
	console.log('file content?', encodedFile);
	let attachmentResponse;
	try {
		let params = {
			rev: entity.rev,
			id: entity.id,
			attachment: encodedFile
		};
		attachmentResponse = await sendPostRequest('eav/entity/setattachment', params);
	} catch (e) {
		throw new Error('File upload failed.');
	}
	return attachmentResponse;
}
