import Store from '@/store/main.js';
import { treeDisplay, toDateFormat, toLocale } from '@/plugins/filters';
import { deepGet } from '@/util/field-helper.js';
import { i18n } from '@/main';

const CORRESPONDENCE_MAIN = '1c4f00c7-325c-4d54-839c-143f29dcfe12'; // Hauptansprechperson
const CORRESPONDENCE_NATIONAL = '2fae1eb4-8337-4051-bc01-ba5d01247c3d'; // Konaktperson fachliches nationale Liste
const CORRESPONDENCE_INVOICE = 'c11245c2-88b1-4662-8760-9a259473598d'; // Kontaktperson Rechnung nationale Liste"

export function replaceLineBreaksWithSeparator(label, separator = ' - ') {
	return label
		.split('<br />')
		.join(separator)
		.replace(/(?:\r\n|\r|\n)/g, separator);
}

export function booleanDisplay(obj) {
	let booleanValue = false;
	if (typeof obj !== 'undefined') {
		booleanValue = obj;
	}

	if (booleanValue) {
		return i18n.t('admin.crud.yes');
	}

	return i18n.t('admin.crud.no');
}

// general path helpers
export function columnSetToPath(columnSet) {
	const appendField = function(path, value) {
		return `${path}/${value || ''}`;
	};

	let p = `${columnSet.listId}/${columnSet.fieldId}`;

	// implicit empty path, if table row id is not filled
	p = appendField(p, columnSet.tableRowId);
	p = appendField(p, columnSet.tableColumnId);
	p = appendField(p, columnSet.subFieldId);
	p = appendField(p, columnSet.packageFilter);

	return p;
}

export function pathToColumnSet(path) {
	const [l, f, r, t, s, z] = path.split('/');

	return {
		listId: l,
		fieldId: f,
		tableColumnId: t || '',
		tableRowId: r || '',
		subFieldId: s || '',
		packageFilter: z || ''
	};
}

function _getFieldById(fieldId) {
	const field = Store.getters['columnset/fieldByPath'](fieldId);
	return field;
}


function _getTableRowsForField(field) {
	if (!field || !field.data.rowSource) {
		return [];
	}

	return Store.getters['product/getOptionsBySource'](field.data.rowSource)
		.slice(0)
		.sort((a, b) => {
			if (!a.text || !b.text) return 0;
			return a.text.localeCompare(b.text);
		});
}

export function getTableRows(fieldPath) {
	const field = _getFieldById(fieldPath);
	return _getTableRowsForField(field);
}

export function extractTableRowTitle(columnset) {
	if (!columnset.tableRowId) {
		return '';
	}

	return extractTableRowTitleByRowAndColumn(columnset.tableRowId, columnset.fieldId);
}

export function extractTableRowTitleByRowAndColumn(tableRowId, fieldId) {
	const tableRows = getTableRows(fieldId);
	const row = tableRows.find(f => f.value === tableRowId);

	return row ? row.text : '';
}

export function unwrapValue(obj) {
	if (!obj) {
		return null;
	}

	// allowed attributes for unwrapping
	const allowedAttributes = ['textValue', 'numberValue', 'booleanValue', 'selectValue', 'multiselectValue', 'dateValue'];

	if (typeof obj === 'object') {
		for (const attribute of allowedAttributes) {
			if (attribute in obj) {
				return obj[attribute];
			}
		}

		return obj;
	}

	return obj;
}

function _getValueFromOptionSource(source, value, exporting) {
	const sourceData = Store.getters['product/getOptionsBySource'](source, exporting);
	if (!sourceData) {
		return value;
	}
	const option = sourceData.find(f => f.value === value);
	if (option) {
		return option.text;
	}
	// console.warn('no option found for source', { value, source, sourceData });
	return null;
}

function _extractSelectValue(field, value, exporting) {
	let optionData = null;

	let source;
	if (exporting && field.data.exportSource) {
		source = field.data.exportSource;
	} else {
		source = field.data.optionSource;
	}
	if (source) {
		const optionSource = _getValueFromOptionSource(source, value, exporting);
		if (optionSource) {
			return optionSource;
		}
	} else {
		optionData = field.data.options.find(f => f.id === value);

		if (optionData) {
			return toLocale(optionData.title);
		}
		// console.warn('no option found', { value, field });
	}

	return value;
}

function _extractMultiSelectValue(field, value, exporting) {
	let aggregate = [];
	for (const item of value) {
		aggregate.push(_extractSelectValue(field, item, exporting));
	}

	return aggregate.join('; ');
}

function _extractTreeValue(field, value) {
	const TREE_FALLBACK = 'category';
	const treeType = field.data.treeType || TREE_FALLBACK;

	let aggregate = [];
	const treeData = [].concat(value);

	for (const treeEntry of treeData) {
		const treeValue = treeDisplay(treeType, treeEntry);
		if (treeValue === null || treeValue.trim() === '') {
			continue;
		}

		aggregate.push(treeValue);
	}

	return aggregate.join('; ');
}

function _parseNestedTableData(tableData, data) {
	let parsedData = [];
	for (const nestedTableObject of Object.values(data)) {
		for (const inner of Object.values(nestedTableObject)) {
			parsedData = parsedData.concat(inner);
		}
	}

	return parsedData.length ? parsedData : tableData;
}

function _extractTableColumnValue(field, data, isNestedTable, exporting) {
	let wrappedTableValue = { textValue: '' };

	let values = [];
	const tableData = data.table ? data.table : [];
	let parsedData = [];

	// nested table structure (base, additional, ...)
	if (isNestedTable && !field.data.preventObjectToArrayMapping) {
		parsedData = _parseNestedTableData(tableData, data);
	} else {
		parsedData = data;

		if (field.data.filter) {
			parsedData = parsedData.filter(f => {
				return f[field.data.filter.column] === field.data.filter.value;
			});
		}
	}
	let lookup = f => {
		const lookupPath = field.data.lookupPath || field.data.path;
		if (f[lookupPath]) {
			const value = _convertValue(field, toLocale(unwrapValue(f[lookupPath])), exporting);
			if (value !== null) {
				values.push(value);
			}
		}
	};
	if (Array.isArray(parsedData)) parsedData.forEach(lookup);
	else lookup(parsedData);

	wrappedTableValue.textValue = values.join(', ');
	return wrappedTableValue;
}

function _convertValue(field, value, exporting) {
	if (!field || !value) {
		return null;
	}

	const fieldType = field.data.type;

	if (fieldType === 'select') {
		if (Array.isArray(value)) {
			return value.map(v => _extractSelectValue(field, v, exporting)).join(', ');
		}
		return _extractSelectValue(field, value, exporting);
	} else if (fieldType === 'multiselect') {
		return _extractMultiSelectValue(field, value, exporting);
	} else if (fieldType === 'treeselect' || fieldType === 'tree') {
		return _extractTreeValue(field, value);
	} else if (fieldType === 'datefield') {
		if (value && typeof value === 'string') {
			return toDateFormat(value);
		}
	} else if (fieldType === 'comment') {
		if (value && Array.isArray(value.comments)) {
			let userField = {
				data: {
					optionSource: 'users'
				}
			};
			return value.comments.reduce((c, v, i) => {
				let m = v.text;
				if (m) {
					m = c + m;
				} else {
					return c;
				}
				if (v.author) {
					let user = _extractSelectValue(userField, v.author);
					// console.log('v.author', v.author, user);
					if (user) {
						m += ` - ${user}`;
					}
				}
				if (v.ts) {
					let d = toDateFormat(v.ts);
					m += ` (${d})`;
				}
				if (i < value.length) m += '; ';
				return m;
			}, '');
		}
	}

	return value;
}

function _extractFieldValue(fieldId, tableColumnId, tableRowId, product, listData, exporting) {
	// console.log('_extract', { fieldId, tableColumnId, tableRowId, product, listData, exporting });
	if (!listData) {
		return null;
	}

	const field = _getFieldById(fieldId);
	if (!field) {
		return null;
	}

	if (field.data.fromRoot) {
		return deepGet(product, [ field.data.path ]);
	}

	if (field.data.keyIsValue) {
		let objectKeys = Object.keys(listData);
		if (tableRowId) {
			objectKeys = objectKeys.filter(f => f === tableRowId);
		}
		return _convertValue(field, objectKeys, exporting);
	}

	const isTabularData = field.data.displayType === 'table' || field.data.displayType === 'special';
	const isNestedTable = field.data.isNestedTable || Object.keys(field.data).length > 0;

	if (isTabularData && (listData.table || isNestedTable)) {
		if (tableRowId) {
			// extract data object value from the selected table row source
			const dataObj = listData[tableRowId];
			if (!dataObj) {
				return '';
			}

			let val = dataObj[field.data.path];
			if (typeof val === 'object') {
				val = unwrapValue(val);
			}

			return _convertValue(field, val, exporting);
		}

		return _extractTableColumnValue(field, listData, isNestedTable, exporting);
	}
	if (field.data.useSourceAsKey) {
		let data = listData[field.data.path];
		let values = Object.keys(data);
		if (field.data.valueFilter) {
			values = values.filter(k => !!data[k]);
		}
		return _convertValue(field, values, exporting);
	}
	const key = field.data.path;
	let valueInternal = listData[key];
	if (!field.data.preventNestedFieldMapping) {
		valueInternal = unwrapValue(valueInternal);
	}

	const value = _convertValue(field, valueInternal, exporting);
	if (tableColumnId) {
		return _extractFieldValue(tableColumnId, null, tableRowId, product, value, exporting);
	}

	return value;
}

/**
 * Resolves an external object and returns an given value from it.
 * Used to resolve the package columns.
 * @param product The product we need to operate on. The external object gets resolved from here
 * @param columnSet The columnset that contains information about which objects to resolve
 * @param source The option source we're operating on. The external object to operate on will be searched in here.
 * @param rootRowFilter The filter metadata which is used to define a context to find the foreign object ID in the given product.
 * Defined on the root level of a package column.
 * @param rootFilterValue The actual value we want to find in the array of objects returned by resolving the rootRowFilter. We can also provide an array as filter value to have afall-back mechanism
 * @param path The path we want to lookup in the external object
 * @param applyFilter Should a filter be applied to the external object? We could have an array of values here instead of an atomar value, so we need to find the actual value
 * @param listFilter Defines the filter that should be used to lookup a value in the external object
 * @param optionSource The source that should be used to resolve an text value assigned to an ID that could be found after applying the listFilter
 * @returns {string|null|*} The actual return value.
 * for linkedPaths the values of 'path' and 'sourceColumn' and targetColumn (representing the linked column ids) must be given
 */
export function getValueFromForeignObject(product, columnSet, source, rootRowFilter, rootFilterValue, path, applyFilter, listFilter, optionSource) {
	// console.log('getValueFromForeignObject', { product, columnSet, source, rootRowFilter, rootFilterValue, path, applyFilter, listFilter, optionSource });
	const _isUsableFilter = function(filter) {
		return filter && filter.path && filter.column && filter.values && Array.isArray(filter.values);
	};

	const _getLinkedPath = function(row, listFilter, foreignObject, listId) {
		if (!listFilter.linkedPath.sourceColumn || !row[listFilter.linkedPath.sourceColumn]) return null;
		let linkedFilter = {
			path: listFilter.linkedPath.path,
			values: [ row[listFilter.linkedPath.sourceColumn] ],
			column: listFilter.linkedPath.targetColumn,
		};
		if (!_isUsableFilter(linkedFilter)) return null;
		return _applyFilter(linkedFilter, foreignObject, listId);
	};

	const _applyFilter = function(listFilter, foreignObject, listId) {
		if (_isUsableFilter(listFilter)) {
			// apply filter by iterating over the child rows.
			const splittedPath = listFilter.path.split('.');
			let rowsToFilter;
			if (Array.isArray(foreignObject) && foreignObject.length > 0) {
				rowsToFilter = deepGet(foreignObject[0], splittedPath);
			} else {
				rowsToFilter = deepGet(foreignObject, splittedPath);
			}
			if (rowsToFilter && Array.isArray(rowsToFilter)) {
				const columnToQuery = listFilter.column;
				if (columnToQuery) {
					// apply predicate
					let rowsFound = [];
					if (listFilter.useContactPersonFilter) {
						rowsToFilter = applyContactPersonFilter(rowsToFilter, listId, listFilter, product);
					}
					for (const value of listFilter.values) {
						for (let idx = 0; idx < rowsToFilter.length; ++idx) {
							const row = rowsToFilter[idx];
							if (row[columnToQuery] === value || listFilter.useContactPersonFilter) {
								if (listId) {
									if (row.type === 'catalog' && row.catalogListId && row.catalogListId !== listId) continue;
									if (row.lists && row.lists.length && !row.lists.includes(listId)) continue;
								}
								if (listFilter.conditions) {
									let allConditionsMet = true;
									for (let [ key, value ] of Object.entries(listFilter.conditions)) {
										if (key in row && row[key] !== value) allConditionsMet = false;
									}
									if (!allConditionsMet) continue;
								}
								if (listFilter.linkedPath) {
									rowsFound.push(_getLinkedPath(row, listFilter, foreignObject, listId));
								} else {
									rowsFound.push(row);
								}
							}
						}
						if (rowsFound.length) break;
					}
					// prioritise items with lists property set
					const itemWithLists = rowsFound.find(item => item && item.lists && item.lists.length);
					return itemWithLists ? itemWithLists : rowsFound.length ? rowsFound[0] : null;
				}
			}
		}

		return null;
	};

	// try to lookup the external objects.
	const foreignRows = Store.getters['product/getOptionsBySource'](source);
	if (!foreignRows) {
		return '';
	}

	let id;
	if (rootRowFilter && rootFilterValue) {
		// filter the product row for the actual id
		const listId = columnSet.listId;
		const filterableData = rootRowFilter.useIndex ? product.data.indexData[listId] : product.data.listData[listId];
		if (filterableData) {
			rootRowFilter.values = [ rootFilterValue ];
			const filterResult = _applyFilter(rootRowFilter, filterableData, listId);

			if (filterResult) {
				if (rootRowFilter.returnValue) {
					id = filterResult[rootRowFilter.returnValue];
				} else {
					id = filterResult;
				}
			} else {
				return '';
			}
		} else {
			return '';
		}
	}

	let foreignObject;
	if (id) {
		foreignObject = foreignRows.find(f => f.id === id);
	} else {
		if (foreignRows.length === 0) {
			return '';
		}

		foreignObject = foreignRows[0];
	}

	if (!foreignObject) {
		return '';
	}

	if (applyFilter && foreignObject) {
		const filteredRow = _applyFilter(listFilter, [ foreignObject ], columnSet.listId);
		if (filteredRow) {
			foreignObject = filteredRow;
		}
	}

	const extractedValue = deepGet(foreignObject, path.split('.'));
	// console.log('extracted', { columnSet, extractedValue });
	if (extractedValue) {
		if (optionSource) {
			const baseData = _getValueFromOptionSource(optionSource, extractedValue);
			if (baseData) {
				return baseData;
			}
		}

		if (Array.isArray(extractedValue)) {
			return extractedValue.join(', ');
		}
	}

	return extractedValue;
}

function applyContactPersonFilter(contactPersons, listId, filter, product) {
	let filteredPersons = [];
	let baseListId = Store.getters['list/baseDataList'].id;
	let mainCategoryId = Store.getters['category/mainCategoryFromIds'](product.data.listData[baseListId]._categoryTree).id;
	let getContactOfType = (persons, personType, listId, categoryId) => {
		let personsOfType = contactPersons.filter(person => person.function === personType);
		if (listId) {
			if (categoryId) {
				let personsOfcategoryList = personsOfType.filter(person => person.jurisdiction && person.jurisdiction.includes(categoryId) && person.lists && person.lists.includes(listId));
				if (personsOfcategoryList.length) {
					return personsOfcategoryList;
				}
			} else {
				let personsOfListNoCategory = personsOfType.filter(person => person.lists && person.lists.includes(listId) && (!person.jurisdiction || !person.jurisdiction.length));
				if (personsOfListNoCategory.length) {
					return personsOfListNoCategory;
				}
			}
		} else if (categoryId) {
			let personsOfCategoryNoLists = personsOfType.filter(person => person.jurisdiction && person.jurisdiction.includes(categoryId) && (!person.lists || !person.lists.length));
			if (personsOfCategoryNoLists.length) {
				return personsOfCategoryNoLists;
			}
		}
		if (!listId && !categoryId) {
			return personsOfType.filter(person => (!person.lists || !person.lists.length) && (!person.jurisdiction || !person.jurisdiction.length));
		}
		return [];
	};

	if (Array.isArray(filter.values)) {
		if (filter.values[0] === CORRESPONDENCE_INVOICE) {
			filteredPersons = getContactOfType(contactPersons, CORRESPONDENCE_INVOICE, listId, mainCategoryId);
			if (!filteredPersons.length) {
				filteredPersons = getContactOfType(contactPersons, CORRESPONDENCE_INVOICE, listId);
			}
			if (!filteredPersons.length) {
				filteredPersons = getContactOfType(contactPersons, CORRESPONDENCE_MAIN);
			}
			if (!filteredPersons.length) {
				filteredPersons = getContactOfType(contactPersons, CORRESPONDENCE_NATIONAL, listId, mainCategoryId);
			}
			if (!filteredPersons.length) {
				filteredPersons = getContactOfType(contactPersons, CORRESPONDENCE_NATIONAL, listId);
			}
		} else if (filter.values[0] === CORRESPONDENCE_MAIN) {
			filteredPersons = getContactOfType(contactPersons, CORRESPONDENCE_NATIONAL, listId, mainCategoryId);
			if (!filteredPersons.length) {
				filteredPersons = getContactOfType(contactPersons, CORRESPONDENCE_NATIONAL, listId);
			}
			if (!filteredPersons.length) {
				filteredPersons = getContactOfType(contactPersons, CORRESPONDENCE_NATIONAL, null, mainCategoryId);
			}
			if (!filteredPersons.length) {
				filteredPersons = getContactOfType(contactPersons, CORRESPONDENCE_MAIN);

			}
		}
	}
	return filteredPersons;
}

export function getProductColumnValue(product, customColumn, exporting) {
	const unknown = { de_DE: '---' };
	const currentList = Store.getters['list/byId'](customColumn.listId);
	const fallbackLocale = currentList && currentList.data.fallbackLanguage ? currentList.data.fallbackLanguage : null;
	const listIds = Object.keys(product.data.listData);

	if (!product || !product.data || !product.data.listData || listIds.length === 0 || !customColumn) {
		return unknown;
	}

	let listData = product.data.listData;

	const list = listData[customColumn.listId];
	if (!list) {
		return unknown;
	}

	let value = _extractFieldValue(customColumn.fieldId, customColumn.tableColumnId, customColumn.tableRowId, product, list, exporting);

	if (customColumn.fieldId === '_components' && (!value || !unwrapValue(value))) {
		let baseListId = Store.getters['list/baseDataList'].id;
		const baseList = listData[baseListId];
		value = _extractFieldValue(customColumn.fieldId, customColumn.tableColumnId, customColumn.tableRowId, product, baseList, exporting);
	}

	if (!value) {
		return unknown;
	}

	return toLocale(value, null, fallbackLocale);
}
