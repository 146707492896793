import { getProductTitle } from '@/util/field-helper';
import { toLocale, booleanDisplay, invoiceFlagDisplay, toDateTimeFormat } from '@/plugins/filters';
import { getProductColumnValue, pathToColumnSet, columnSetToPath, getValueFromForeignObject, replaceLineBreaksWithSeparator } from '@/util/search/columnset';
import { generateSortFields } from '@/util/search/sort-util';
import { constructSelectionFilter } from '@/util/search/query-generator';
import { searchCursorWithGenerator } from '@/repository/system';
import { loadProduct, countProducts, getProductTimeline } from '@/repository/product';
import { i18n } from '@/main';
import { deepGet } from '@/util/field-helper.js';
import { chunk } from 'lodash';
import { mergeListAndBase } from '../product';

const DESTRUCTIBLE_FIELDS = [{
	keyMapping: 'category',
	path: '_categoryTree',
	searchPath: '_category',
	getter: 'category/pathToRootOrdered',
	multiGetter: 'category/byIds',
	singleGetter: 'category/byId',
	maxColumnCount: 5,
	displayValues: ['title', 'order']
}];

const BATCH_SIZE = 5;

function _insertArrayAt(array, index, arrayToInsert) {
	Array.prototype.splice.apply(array, [index, 0].concat(arrayToInsert));
	return array;
}

const INVOICE_FIELDS = [
	{
		key: 'invoiceFlag'
	},
	{
		key: 'invoiceFlagDate'
	}
];

function _getProductsWithFlag(products, invoiceFlag) {
	return products.filter(f => f.data.invoiceFlags && f.data.invoiceFlags[invoiceFlag]);
}

const state = {
	fields: [],
	finalFields: [],
	companyCache: [],
	timelines: {},
	exportLanguage: null
};

function getColumn(columnset, index) {
	return columnset.data.configuration.columns[index];
}

export function constructSheet(headers, rows) {
	let sheet = [];
	for (const row of rows) {
		let rowObj = {};
		for (const header of headers) {
			const value = row[header.key];
			rowObj[header.label] = value || '';
		}

		sheet.push(rowObj);
	}

	return sheet;
}

/**
 * Unwraps a package column to the corresponding subcolumns.
 * @param fieldId
 * @returns {*[]}
 * @private
 */
function _unwrapPackage(getters, fieldId) {
	const field = getters['exportset/fieldById'](fieldId);
	if (!field || !field.data.isPackage) {
		return [];
	}

	const subColumns = getters['exportset/fieldsByParent'](field.id);
	return subColumns;
}

function _unwrapSinglePackageField(columnSet, parentField, subField, parentKey) {
	// inject data source of parent into the sub field.
	const subFieldCopy = JSON.parse(JSON.stringify(subField));

	if (parentField.data.filter) {
		// we furthermore need to apply the option source to the fields.
		subFieldCopy.data.exportSource = parentField.data.foreignKeySource;

		// the getProductColumnValue function might now be able to extract
		// the corresponding company id from the product and
		// fetch the foreign key data from the cache.
		// this can be used to extract the actual value from the foreign key data.

		const currentSet = JSON.parse(JSON.stringify(columnSet));
		currentSet.subFieldId = subField.id;
		subFieldCopy.data.pathInternal = columnSetToPath(currentSet);
		subFieldCopy.data.path = subField.data.pathPrefix ? `${subField.data.pathPrefix}.${subField.data.path}` : subField.data.path;
		subFieldCopy.key = subFieldCopy.data.pathInternal;
		subFieldCopy.data.isPackageField = true;
	} else {
		subFieldCopy.key = subFieldCopy.id;
	}

	subFieldCopy.data.applyFilter = !!parentField.data.listRows;

	if (parentField.data.filter) {
		// copy root filter to be applied onto the row source
		subFieldCopy.data.rootRowFilter = parentField.data.filter;

		if (!subField.data.filter) {
			// copy the parent filter to the current element.
			subFieldCopy.data.filter = parentField.data.filter;
		}

		if (parentKey) {
			const parentSet = pathToColumnSet(parentKey);
			subFieldCopy.data.rootRowFilter.filterValue = parentSet.packageFilter;
		}
	}

	return subFieldCopy;
}

function _destructureRows(row, product, listId, fieldKey, getters) {
	const _cloneRow = function(row) {
		return JSON.parse(JSON.stringify(row));
	};

	// assume there is only 1 top-level root element selected
	const _findDiffingSubtrees = function(treeData, getters, fieldMapping) {
		const _getTreeStructure = function() {
			const objectData = JSON.parse(JSON.stringify(getters[fieldMapping.multiGetter](treeData)));
			return objectData;
		};

		const _buildSubtrees = function(leafs) {
			const subtrees = [];
			for (const leaf of leafs) {
				const pathToRoot = getters[fieldMapping.getter](leaf.id);
				if (pathToRoot) {
					const currentSubtree = [];
					for (let idx = Object.keys(pathToRoot).length - 1; idx >= 0; idx--) {
						currentSubtree.push(pathToRoot[idx]);
					}

					subtrees.push(currentSubtree);
				}
			}

			return subtrees;
		};

		const tree = _getTreeStructure(treeData);
		return _buildSubtrees(tree);
	};

	const rowResults = [];

	const columnset = pathToColumnSet(fieldKey);
	if (!columnset) {
		return [];
	}

	const fieldMapping = DESTRUCTIBLE_FIELDS.find(f => f.path === columnset.fieldId);
	if (!fieldMapping) {
		return [];
	}

	const baseListId = getters['list/baseDataList'].id;
	let listData = product.data.listData[listId];
	let baseData = product.data.listData[baseListId];
	let mergedData = listData && baseData ? mergeListAndBase(listData, baseData) : baseData;
	const values = deepGet(mergedData, fieldMapping.searchPath.split('.'));

	// 1) find an array of subtrees based on the current input data
	const subTrees = _findDiffingSubtrees(values, getters, fieldMapping);

	// for each subtree parent, generate a new row following the rule:
	// create an n-ary array where each array contains the elements of the current leaf-to-root path

	// 2) create a new row per entry in the n-ary array and override the field value by the current iterator value
	for (const subTree of subTrees) {
		// 3.n) push the row to the result array
		const newRow = _cloneRow(row);

		// cleanup source columns
		for (let i = 0; i < fieldMapping.maxColumnCount; ++i) {
			newRow[fieldKey + (i + 1)] = '';
		}

		let idx = 1;
		const fieldCount = fieldMapping.displayValues ? fieldMapping.displayValues.length : 1;
		const maxCount = fieldCount * fieldMapping.maxColumnCount;
		const displayValues = fieldMapping.displayValues || [ 'title' ];

		for (const subtreeEntry of subTree) {
			for (let displayIndex = 0; displayIndex < fieldCount; ++displayIndex) {
				const displayValue = displayValues[displayIndex];
				const path = `${fieldKey}/${displayValue}/${idx}`;
				newRow[path] = toLocale(subtreeEntry.data[displayValues[displayIndex]]);
			}

			++idx;

			if (idx > maxCount) {
				break;
			}
		}

		rowResults.push(newRow);
	}

	return rowResults;
}

function filterArchivedLists(products) {
	for (let product of products) {
		if (product.data.listData) {
			for (let [listId, listData] of Object.entries(product.data.listData)) {
				if (listData._archived && typeof listData._archived === 'boolean') {
					delete product.data.listData[listId];
				}
			}
		}
	}
	return products.filter(product => !!Object.keys(product.data.listData).length);
}

const getters = {
	getFields: (state) => {
		return state.fields;
	},
	getFinalFields: (state) => {
		return state.finalFields;
	},
	getFinalFieldsWithExpandedCategory: (state) => {
		let base = state.finalFields;
		let idx = base.findIndex(e => e.id === 'category');
		if (idx < 0) return base;
		let fields = base.slice(0);
		let catField = JSON.stringify(base[idx]);
		let newFields = [];
		for (let i=1; i<=5; i++) {
			let copy = JSON.parse(catField);
			copy.key += `/title/${i}`;
			copy.label += ` ${i18n.t('product.search.export.packageColumns.category.title')} ${i}`;
			newFields.push(copy);
			copy = JSON.parse(catField);
			copy.key += `/order/${i}`;
			copy.label += ` ${i18n.t('product.search.export.packageColumns.category.number')} ${i}`;
			newFields.push(copy);
		}
		fields.splice(idx, 1, ...newFields);
		return fields;
	}
};

const actions = {
	// generates the list of companies that are actually needed for an given set of products
	async _createCompanyCache({ commit, dispatch }, products) {
		const uniqueIds = new Set();
		for (const product of products) {
			for (const listData of Object.values(product.data.listData)) {
				if (!('_companies' in listData)) {
					continue;
				}
				listData._companies.forEach(f => uniqueIds.add(f.company));
			}
		}
		const companies = await dispatch('companylist/loadByIds', Array.from(uniqueIds), { root: true });
		commit('setCompanyCache', companies);
	},

	async _loadProductsByInvoiceFlag(_, { products, invoiceFlag }) {
		if (!invoiceFlag) {
			return products;
		}

		let productData = [];
		const batches = chunk(products, BATCH_SIZE);

		async function _loadAndAddProduct(productId, revision) {
			const product = await loadProduct(productId, revision);
			productData.push(product);
		}

		for (const batch of batches) {
			const promises = [];
			const productsWithFlag = _getProductsWithFlag(batch, invoiceFlag);

			for (const product of productsWithFlag) {
				const revision = product.data.invoiceFlags[invoiceFlag];
				promises.push(_loadAndAddProduct(product.id, revision));
			}

			await Promise.all(promises);
		}

		return productData;
	},

	loadCount({ rootGetters }) {
		const filter = rootGetters['search/getSearchFilter'];
		return countProducts(filter);
	},

	async loadExportHeader({ commit, getters, state, rootGetters, dispatch }, { invoiceFlag }) {
		if (invoiceFlag) {
			// we need to make sure we can display the invoice flag description text in an additional column.
			await dispatch('selectoptions/loadAll', { ifEmpty: true }, { root: true });
		}

		// the company cache contains all companies ever used in the set of products to be exported.
		const fields = state.fields;
		const columnset = rootGetters['exportset/getSelectedColumnSet'];

		// unwrap packages
		const allFields = JSON.parse(JSON.stringify(fields));
		let colIdx = 0;

		const headersToExpand = new Map();
		for (const field of fields) {
			const fieldData = rootGetters['exportset/fieldById'](field.id);
			// convert any given package field into a set of subsequential sub fields
			if (fieldData && field.key !== 'name') {
				const parentPath = getColumn(columnset, colIdx - 1);
				if (!parentPath) {
					++colIdx;
					continue;
				}

				const currentColumnSet = pathToColumnSet(parentPath);

				if (fieldData.data.isPackage && fieldData.data.children) {
					// if necessary, get the child package fields
					const subColumns = _unwrapPackage(rootGetters, fieldData.id);
					// console.log('unwrapped subcols', subColumns);
					// push all child fields with their corresponding filters at the current pointer
					subColumns.forEach(subColumn => {
						if (!allFields.find(currentField => currentField.label === subColumn.label)) {
							allFields.push(_unwrapSinglePackageField(currentColumnSet, fieldData, subColumn, field.key));
						} else {
							console.warn('duplicate label', subColumn.label);
						}
					});

					// remove the parent (package field) from the list of all fields
					allFields.splice(colIdx, 1);
				}
			}

			++colIdx;
		}

		// insert additional fields.
		if (invoiceFlag) {
			for (let invoiceField of INVOICE_FIELDS) {
				invoiceField.label = i18n.t(`product.search.export.invoiceFlag.${invoiceField.key}`);
			}

			_insertArrayAt(allFields, 1, INVOICE_FIELDS);
		}

		// commit all fields we'll finally need to display
		// those are:
		// * default fields (selected in the export set)
		// * invoice flag fields (if option was selected)
		// * package subfields with their corresponding filter data
		commit('setFinalFields', allFields);

		// generate additional header columns
		for (const [key, length] of headersToExpand) {
			commit('expandHeaders', { key, length });
		}
		return getters.getFinalFieldsWithExpandedCategory.map(f => replaceLineBreaksWithSeparator(f.label));
	},

	async* loadExportRow({ rootState, state, rootGetters, getters, dispatch }, { invoiceFlag, outputRevision }) {

		// the company cache contains all companies ever used in the set of products to be exported.
		const fields = state.fields;
		const columnset = rootGetters['exportset/getSelectedColumnSet'];

		// check if an given default column (revision, created, ...) was checked for displaying
		const hasColumn = function(column) {
			return !!fields.find(f => f.key === column);
		};

		let websiteKey = '';


		const headersToExpand = new Map();

		const allFields = state.finalFields;
		const countAll = await dispatch('loadCount');
		let loadCache = [];
		let loadLimit = 250;

		const productSelection = rootState.search.selection;
		const productFilter = constructSelectionFilter(rootGetters['search/getSearchFilter'], productSelection);
		const sorting = rootGetters['productlist/getSort'];
		let sortObj = sorting ? sorting : state.sort;
		sortObj = generateSortFields(sortObj);
		if (!sortObj.length) sortObj = null;
		const productGenerator = searchCursorWithGenerator(null, productFilter, 'POST', 'bml/product/search', sortObj);
		let doneLoading = false;
		const loadProductBatch = async() => {
			let products = [];
			for (let i=0; i<loadLimit; i++) {
				let p = await productGenerator.next();
				if (p.value) {
					products.push(p.value);
				}
				if (p.done) {
					doneLoading = true;
					break;
				}
			}
			if (invoiceFlag) {
				if (outputRevision) {
					products = await dispatch('_loadProductsByInvoiceFlag', { products, invoiceFlag });
				}
			}
			products = filterArchivedLists(products);
			await dispatch('_createCompanyCache', products);
			loadCache = products;
		};
		const toRow = (result) => {
			// console.log('toRow', result, state.finalFields);
			return getters.getFinalFieldsWithExpandedCategory.map(f => {
				let v = result[f.key] || '';
				return v;
			});
		};

		// iterate over each products and extract the row data depending on the current field data
		for (let i=0; i<countAll; i++) {
			if (loadCache.length === 0) {
				if (doneLoading) break;
				await loadProductBatch();
			}
			let product = loadCache.shift();
			// if (outputRevision) {
			// 	let revision = product.data.invoiceFlags[invoiceFlag];
			// 	product = await loadProduct(product.id, revision);
			// }
			let row = {};
			// we always display the product name.
			row.name = toLocale(getProductTitle(product));
			if (invoiceFlag) {
				//@TODO do not load timeline when outputRevision is selected. use /updated instead
				if (outputRevision) {
					row.invoiceFlag = invoiceFlagDisplay(product, null, invoiceFlag, false);
					row.invoiceFlagDate = toDateTimeFormat(product.updated);
				} else {
					const timeline = await getProductTimeline(product.id);
					row.invoiceFlag = invoiceFlagDisplay(product, null, invoiceFlag, false);
					row.invoiceFlagDate = invoiceFlagDisplay(product, timeline, invoiceFlag, true);
				}
			}
			if (hasColumn('rev')) {
				row.rev = product.rev;
			}
			if (hasColumn('data.isNationalPartner')) {
				row.isNationalPartner = booleanDisplay(product.data.isNationalPartner);
			}
			if (hasColumn('created')) {
				row.created = toDateTimeFormat(product.created);
			}
			if (hasColumn('updated')) {
				row.updated = toDateTimeFormat(product.updated);
			}
			// generate the row data for each field
			for (let field of allFields) {
				let currentSet;
				let val;
				let loadFallbackLanguage = (currentSet) => {
					let listFallback = rootState.base.fallbackLocale;
					if (currentSet.listId) {
						let list = rootGetters['list/byId'](currentSet.listId);
						if (list && list.data.fallbackLanguage) {
							listFallback = list.data.fallbackLanguage;
						}
					}
					return listFallback;
				};
				// package fields, like company data, need to resolve the foreign object (for example product -> company)
				if (field.data && field.data.isPackageField) {
					// pathInternal is used to prevent duplicated paths in our list of possible columns
					currentSet = pathToColumnSet(field.data.pathInternal, true);
					let listFallbackLanguage = loadFallbackLanguage(currentSet);
					// extract the value and append it to the current column.
					// the parameters get resolved from the package-fields json description
					// @todo: some of the filters depend on the IDs as there was no other unique identifier available. this is dangerous.
					val = toLocale(getValueFromForeignObject(product, currentSet, field.data.exportSource, field.data.rootRowFilter, field.data.rootRowFilter.filterValue,
						field.data.path, field.data.applyFilter, field.data.filter, field.data.optionSource), null, listFallbackLanguage);
					if (field.key in row) {
						console.log('Doublicated field in export config. Field name is: ', field.key);
					}
					if (field.data.override && field.data.override.substituteField) {
						let newField = field.data.override.substituteField;
						let overrideValue = toLocale(getValueFromForeignObject(
							product,
							currentSet,
							field.data.exportSource,
							field.data.rootRowFilter,
							field.data.rootRowFilter.filterValue,
							newField.data.path,
							field.data.applyFilter,
							newField.data.filter,
							field.data.optionSource
						), null, listFallbackLanguage);
						if (overrideValue && overrideValue !== '---') {
							row[field.key] = overrideValue;
						} else {
							row[field.key] = val;
						}
					} else {
						row[field.key] = val;
					}
				} else {
					const currentSetIndex = columnset.data.configuration.columns.findIndex(f => f === field.key);
					if (currentSetIndex === -1) {
						continue;
					}

					currentSet = pathToColumnSet(getColumn(columnset, currentSetIndex));
					let listFallbackLanguage = loadFallbackLanguage(currentSet);

					// in any other case, just extract the product column value like in the product list
					val = toLocale(getProductColumnValue(product, currentSet, true), null, listFallbackLanguage);

					// do some additional post-processing, if necessary

					// used to split categories into sub-fields
					if (field.splitArray) {
						let separatedValues = val.split(';');

						if (field.reverseValues) {
							separatedValues = separatedValues.reverse();
						}

						// use only n values, if there is a maximum amount of columns provided for the corresponding field
						if (field.maxColumns && separatedValues.length >= field.maxColumns) {
							separatedValues = separatedValues.slice(0, field.maxColumns);
						}

						// create the actual column values
						for (let i = 0; i < separatedValues.length; ++i) {
							row[field.key + (i + 1)] = separatedValues[i].trim();
						}

						// convert a single header column into a list of n (maximum provided)
						// or arbitrary amount of columns. this just prepares an map that stores
						// the maximum amount of columns for any given key (e. g. _categories)
						const maxHeaderLength = headersToExpand.get(field.key);
						if (!maxHeaderLength) {
							let newCount = separatedValues.length;
							const fieldMapping = DESTRUCTIBLE_FIELDS.find(f => f.keyMapping === field.id);
							if (fieldMapping && fieldMapping.maxColumnCount) {
								const displayValuesCount = fieldMapping.displayValues ? fieldMapping.displayValues.length : 1;
								newCount = fieldMapping.maxColumnCount * displayValuesCount;
							}

							headersToExpand.set(field.key, newCount);
						}
					} else {
						if (field.key in row) {
							console.log('Doublicated field in export config. Field name is: ', field.key);
						}
						row[field.key] = val;
					}
				}

				field.listId = currentSet.listId;

				// BML-607 copy website from basis to address data if empty
				if (field.id === 'website' && val) {
					websiteKey = field.key;
				}
				if (field.id === 'websiteAddress' && val === '---' && websiteKey) {
					row[field.key] = row[websiteKey];
				}
			}

			let isSplit = false;
			// all fields have been processed, now split the rows, if neccessary
			// this is necessary as tree columns like _categories need to be separated
			// so that any sub-tree gets shown as additional product row.
			// this basically replaces a single product row with n product rows
			// whereas n = count of sub-trees
			for (const field of allFields) {
				if ((!field.data || !field.data.isPackageField) && field.splitArray && field.splitRows) {
					const newRows = _destructureRows(row, product, field.listId, field.key, rootGetters);
					for (let row of newRows) {
						yield toRow(row);
					}
					isSplit = true;
				}
			}
			if (!isSplit) {
				yield toRow(row);
			}
		}

	},


};

const mutations = {
	setFields(state, fields) {
		state.fields = fields.filter(f => f.key !== 'actions' && f.key !== 'check');
	},
	setTimelines(state, timelines) {
		state.timelines = timelines;
	},
	setFinalFields(state, fields) {
		state.finalFields = fields;
	},
	setCompanyCache(state, companies) {
		state.companyCache = companies;
	},
	setExportLanguage(state, language) {
		state.exportLanguage = language;
	},
	expandHeaders(state, { key, length }) {
		// Creates the header-columns for cases where we need n*m separate columns where n = amount of columns per field (e. g. category 1-5) and m = count of subcolumns (e. g. title, number, ...)
		const _getColumnTitle = function(id, displayValue, index) {
			// columns titles are formatted with the current index as suffix
			return `${i18n.t(`product.search.export.packageColumns.${id}.${displayValue}`)} ${index}`;
		};

		const baseIndex = state.finalFields.findIndex(f => f.key === key);

		// insert n copies with separate key and title
		const baseField = state.finalFields[baseIndex];
		const newElements = [];
		console.log('expandHeaders', baseField);
		const fieldMapping = DESTRUCTIBLE_FIELDS.find(f => f.keyMapping === baseField.id);
		const displayValues = fieldMapping.displayValues || [ 'title' ];
		const fieldCount = displayValues.length;

		for (let i = 0; i < length / fieldCount; ++i) {
			for (let j = 0; j < fieldCount; ++j) {
				const newField = JSON.parse(JSON.stringify(baseField));
				const displayValue = displayValues[j];
				newField.key += `/${displayValue}/${i + 1}`;
				newField.label += ` ${_getColumnTitle(newField.id, displayValue, i + 1)}`;
				newElements.push(newField);
			}
		}

		state.finalFields.splice(baseIndex, 1);
		_insertArrayAt(state.finalFields, baseIndex, newElements);
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
