import * as Repo from '@/repository/selectoptions.js';
import { getEntityclassByExternalId, getAttributesetByExternalId } from '@/repository/system.js';
import SelectTypes from '@/assets/config/select-types.json';

const state = {

	types: SelectTypes,
	options: [],
	attributesetId: '',
	entityclassId: ''

};

const getters = {

	byId: (state) => (id) => {
		return state.options.find(r => r.id === id);
	},

	byType: (state) => (type) => {
		return state.options.filter(e => e.data.type === type);
	},

	byTypeSorted: (state) => (type) => {
		return state.options
			.filter(e => e.data.type === type && e.data.enabled)
			.sort((a, b) => {
				return a.data.order < b.data.order ? -1 : 1;
			});
	},

	all: (state) => {
		return state.options;
	}

};

const actions = {

	async loadAll({ state, commit }, { ifEmpty }) {
		if (ifEmpty && state.options.length > 0) {
			return;
		}
		if (!state.entityclassId) {
			let ec = await getEntityclassByExternalId('selectoptions');
			commit('setEcId', { id: ec.id });
		}
		let list = await Repo.getAllOptions(state.entityclassId);
		commit('setOptions', list);
	},

	async create({ state, commit }, data) {
		if (!state.entityclassId) {
			let ec = await getEntityclassByExternalId('selectoptions');
			commit('setEcId', { id: ec.id });
		}
		if (!state.attributesetId) {
			let ec = await getAttributesetByExternalId('selectoption');
			commit('setAsId', { id: ec.id });
		}
		let entity = await Repo.createOption(data, state.entityclassId, state.attributesetId);
		commit('addOption', entity);
	},

	async update({ state, commit, getters }, entry) {
		if (!state.attributesetId) {
			let ec = await getAttributesetByExternalId('selectoption');
			commit('setAsId', { id: ec.id });
		}
		let oldEntry = getters.byId(entry.id);
		let updated = await Repo.updateOption(oldEntry, entry.data, state.attributesetId);
		if (updated) {
			commit('deleteOption', { id: entry.id });
			commit('addOption', updated);
		}
	}

};

const mutations = {

	addOption(state, entity) {
		state.options.push(entity);
	},
	setOptions(state, list) {
		state.options = list;
	},
	deleteOption(state, { id }) {
		let entity = getters.byId(state)(id);
		if (!entity) throw new Error(`entity not found: ${id}`);
		let idx = state.options.indexOf(entity);
		state.options.splice(idx, 1);
	},


	setEcId(state, { id }) {
		state.entityclassId = id;
	},
	setAsId(state, { id }) {
		state.attributesetId = id;
	}

};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
