import * as Repo from '@/repository/company.js';
import { getEntityclassByExternalId, getAttributesetByExternalId, searchCursorWithGenerator } from '@/repository/system.js';
import Roles from '@/assets/config/company-roles.json';
import documents from './company/documents';
import checklist from './company/checklist';
import {countEntries} from '@/repository/system';
import {generateSearchField} from '@/util/company/sort';
import {toLocale} from '@/plugins/filters';

const ENTITY_CLASS_ID = 'company';

const modules = {
	documents,
	checklist
};

const state = {

	companies: [],
	roles: Roles,
	search: null,
	searchCount: 0,
	activePageNum: 1,
	perPage: 25,
	attributesetId: '',
	entityclassId: '',
	sort: null,
	lastQuery: null,
	companyKept: null,
	companyDeleted: null

};

const getters = {

	byId: (state) => (id) => {
		return state.companies.find(r => r.id === id);
	},

	all: (state) => {
		return state.companies;
	},

	getSearch: (state) => {
		return state.search;
	},

	getActivePageNum: (state) => {
		return state.activePageNum;
	},

	getPerPage: (state) => {
		return state.perPage;
	},

	getSort: (state) => {
		return state.sort;
	},

	getCompanyTitle: (state, getters, rootState, rootGetters) => (company) => {
		let str = company.baseData.name ? company.baseData.name : '-';

		let suffix = [];
		if (company.baseData.customerNumber) {
			suffix.push(company.baseData.customerNumber);
		}

		if (company.baseData.owner) {
			const institution = rootGetters['auth/org/byId'](company.baseData.owner);
			if (institution) {
				suffix.push(toLocale(institution.data.title));
			}
		}

		if (suffix.length > 0) {
			str = `${str} (${suffix.join(', ')})`;
		}

		return str;
	}

};

const actions = {
	async _setEntityClassId({ state, commit }) {
		if (!state.entityclassId) {
			const entityClass = await getEntityclassByExternalId(ENTITY_CLASS_ID);
			commit('setEcId', entityClass);
		}
	},

	async _setAttributeSetId({ state, commit }) {
		if (!state.attributesetId) {
			let ec = await getAttributesetByExternalId(ENTITY_CLASS_ID);
			commit('setAsId', { id: ec.id });
		}
	},

	async getById(_, id) {
		let entity = await Repo.getCompanyById(id);
		return entity;
	},

	async create({ state, commit, dispatch }, data) {
		await dispatch('_setEntityClassId');
		await dispatch('_setAttributeSetId');
		let entity = await Repo.createCompany(state.entityclassId, state.attributesetId, data);
		commit('addCompany', entity);
		return entity;
	},

	async update({ state, commit, dispatch, getters }, entry) {
		await dispatch('_setAttributeSetId');
		let oldEntry = getters.byId(entry.id);
		if (!oldEntry) oldEntry = await Repo.getCompanyById(entry.id);
		let updated = await Repo.updateCompany(oldEntry, entry.data, state.attributesetId);
		if (updated) {
			commit('deleteCompany', { id: entry.id });
			commit('addCompany', updated);
		}
	},

	async delete({ commit, dispatch }, { entry }) {
		if (typeof entry === 'undefined') {
			return;
		}

		await Repo.deleteCompany(entry.id);
		commit('deleteCompany', { id: entry.id });
	},

	async search({ commit, dispatch }, { filter, pagination, sorting, ifEmpty }) {
		if (ifEmpty && state.companies.length > 0) return;
		await dispatch('_setEntityClassId');

		let sortObj = sorting ? sorting : state.sort;
		sortObj = generateSearchField(sortObj);

		const paging = pagination || { start: 0, limit: state.perPage };
		const currentFilter = filter || state.lastQuery;

		let list = await Repo.searchCompanies(state.entityclassId, currentFilter, paging, sortObj);
		if (pagination && list.length < paging.limit) {
			commit('setCount', { count: list.length + paging.start });
		} else {
			let count = await countEntries(state.entityclassId, currentFilter);
			commit('setCount', { count });
		}

		commit('resetCompanies', list);
		return list;
	},

	async getCompanyCount({ dispatch }, attributeFilter) {
		await dispatch('_setEntityClassId');
		return countEntries(state.entityclassId, attributeFilter);
	},

	async loadBatch({ state, dispatch }, { filter }) {
		await dispatch('_setEntityClassId');
		let generator = searchCursorWithGenerator(state.entityclassId, filter, 'POST', 'search/search/cursor');
		return generator;
	},

	async loadAll({ commit, state, dispatch }, { ifEmpty }) {
		if (ifEmpty && state.companies.length > 0) return;
		await dispatch('_setEntityClassId');

		let list = await Repo.searchCompanies(state.entityclassId);
		commit('resetCompanies', list);
	},

	async loadByIds({ state, dispatch, commit }, ids) {
		const filteredIds = ids.filter(id => id !== '' && !state.companies.find(e => e.id === id));
		if (filteredIds.length > 0) {
			await dispatch('_setEntityClassId');
			const data = await Repo.getCompaniesByIds(state.entityclassId, filteredIds);
			commit('addCompany', data);
		}
		return state.companies.filter(e => ids.includes(e.id));
	},

	updateProductsAfterMerge(_, companyIds) {
		return Repo.updateProductsAfterMerge(companyIds);
	},

	async loadByNameOrCustomerNumber({ state, dispatch }, value) {
		await dispatch('_setEntityClassId');

		const filter = {
			$or: [
				{
					field: '/data/baseData/name',
					comparison: 'eq',
					// fuzzy: true,
					use: 'ngram',
					value
				},
				{
					field: '/data/baseData/customerNumber',
					comparison: 'eq',
					fuzzy: true,
					value
				}
			]
		};

		const data = await Repo.searchCompanies(state.entityclassId, filter);
		return data;
	}

};

const mutations = {

	addCompany(state, entity) {
		let rows = Array.isArray(entity) ? entity : [entity];
		for (let entity of rows) {
			let idx = state.companies.findIndex(e => e.id === entity.id);
			if (idx > -1) {
				state.companies.splice(idx, 1, entity);
			} else {
				state.companies.push(entity);
			}
		}
	},
	deleteCompany(state, { id }) {
		let entity = getters.byId(state)(id);
		if (!entity) return;
		let idx = state.companies.indexOf(entity);
		state.companies.splice(idx, 1);
	},
	resetCompanies(state, l) {
		state.companies = l || [];
	},
	setEcId(state, { id }) {
		state.entityclassId = id;
	},
	setAsId(state, { id }) {
		state.attributesetId = id;
	},
	setSearch(state, search) {
		state.search = search;
	},
	setActivePageNum(state, page) {
		state.activePageNum = page;
	},
	setPerPage(state, page) {
		state.perPage = page;
	},
	setCount(state, { count }) {
		state.searchCount = count;
	},
	setSort(state, sort) {
		state.sort = sort;
	},
	setLastQuery(state, query) {
		state.lastQuery = query;
	},
	setCompanyKept(state, company) {
		state.companyKept = company;
	},
	setCompanyDeleted(state, company) {
		state.companyDeleted = company;
	}
};

export default {
	namespaced: true,
	modules,
	state,
	getters,
	actions,
	mutations
};
