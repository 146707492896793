import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import product from './modules/product.js';
import category from './modules/category.js';
import categorytext from './modules/categorytext.js';
import restrictiontext from './modules/restrictiontext.js';
import biostandard from './modules/biostandard.js';
import checklist from './modules/checklist.js';
import selectoptions from './modules/selectoptions.js';
import country from './modules/country.js';
import list from './modules/list.js';
import permit from './modules/permit.js';
import field from './modules/field.js';
import invoice from './modules/invoice.js';
import component from './modules/component.js';
import hobbygarden from './modules/hobbygarden.js';
import productlist from './modules/productlist.js';
import companylist from './modules/companylist.js';
import label from './modules/label.js';
import search from './modules/search/main.js';
import columnset from './modules/columnset/main.js';
import exportset from './modules/exportset/main.js';
import auth from './modules/auth/main.js';
import messages from './modules/messages';
import batchtemplate from './modules/batchtemplate';
import paiduser from './modules/paiduser';
import base from './modules/base.js';

Vue.use(Vuex);

const persistence = createPersistedState({
	key: 'bml_auth',
	storage: window.localStorage,
	paths: ['auth.login']
});

const logger = store => {
	// called when the store is initialized
	store.subscribe((mutation) => {
		console.debug('mutation: ', mutation);
	});
};

// disabled createLogger in dev mode as it really slows down the dev process
const plugins = process.env.NODE_ENV !== 'development'
	? [ persistence ]
	: [ persistence, logger ];

export default new Vuex.Store({
	modules: {
		product,
		category,
		categorytext,
		restrictiontext,
		biostandard,
		checklist,
		selectoptions,
		country,
		list,
		document,
		permit,
		field,
		component,
		hobbygarden,
		productlist,
		companylist,
		label,
		invoice,
		search,
		columnset,
		exportset,
		auth,
		messages,
		batchtemplate,
		paiduser,
		base
	},
	strict: true,
	plugins
});
