<template>
	<layout-maintenance v-if="isMaintenance" />
	<div
		v-else
		id="appbody"
		class="grid-container"
		:class="showSidebar ? 'grid-container-columns-full' : 'grid-container-columns-hidden-sidebar'"
	>
		<vue-progress-bar />
		<messages />
		<main :class="mainClass()">
			<div class="container-fluid px-0">
				<div class="row no-gutters">
					<div class="col">
						<div
							v-if="loading"
							class="w-100 h-100 d-flex justify-content-center align-content-center loading-indicator"
						>
							<img src="@/assets/images/Wedges-3s-200px.svg">
						</div>
						<router-view />
					</div>
				</div>
			</div>
		</main>
		<div class="sidenav bg-secondary text-white" :style="{ display: showSidebar ? 'inherit' : 'none' }">
			<b-nav
				vertical
				class="nav-header"
				:key="group"
				v-for="group in navGroups"
			>
				<header class="bg-dark text-white" v-if="group">
					{{ group }}
				</header>
				<b-nav-item
					v-for="(item, index) in navItems(group)"
					:key="index"
					:to="item.path"
				>
					{{ $t(`default.router.sidebar.${item.name}`) }}
				</b-nav-item>
			</b-nav>
		</div>
		<div :class="isDemo ? 'navbar bg-danger text-white' : 'navbar bg-dark text-white'">
			<div id="globalWarnings" class="col m-0 app-title">
				{{ productTitle }}{{ $t(`default.router.title.${$route.name}`) }}
				<template v-if="subTitle">
					<br>
					<span class="subtitle">
						{{ subTitle }}
					</span>
				</template>
			</div>
			<settings-panel ref="settingsPanel" />
			<account-select
				class="ml-2"
			/>
			<b-button
				@click="toggleSidebar()"
				class="ml-2"
				:title="$t('default.toggleSidebar')"
			>
				<v-icon icon="bars" class="m-0" />
			</b-button>
			<b-button
				class="ml-3"
				variant="warning"
				v-if="canGoBack"
				@click="goBack"
			>
				&#171; {{ $t('default.common.back') }}
			</b-button>
		</div>
		<footer class="footer bg-dark text-white">
			&copy; {{ new Date().getFullYear() }} FiBL
		</footer>
		<div class="logo" :style="{ display: showSidebar ? 'inherit' : 'none' }">
			<b-link to="/">
				<img
					height="60"
					class="p-3"
					alt="Logo"
					src="@/assets/images/logo.png"
				>
			</b-link>
		</div>
		<b-modal
			ref="errorModal"
			scrollable
			size="lg"
			:title="$t('default.common.error.title')"
			ok-only
		>
			<div>
				<p>
					<strong>
						{{ $t('default.common.error.occured') }}
					</strong>
				</p>
				<ul>
					<li
						v-for="line in lastError"
						:key="line"
					>
						{{ line }}
					</li>
				</ul>
			</div>
			<p>{{ $t('default.common.error.details') }}</p>
			<template slot="modal-ok">
				{{ $t('default.common.error.submit') }}
			</template>
		</b-modal>
		<b-modal
			ref="warningModal"
			scrollable
			size="lg"
			:title="$t('default.common.warnings.title')"
			ok-only
		>
			<div>
				<ul>
					<li
						v-for="line in warnings"
						:key="line"
					>
						{{ line }}
					</li>
				</ul>
			</div>
			<template slot="modal-ok">
				{{ $t('default.common.warnings.submit') }}
			</template>
		</b-modal>
		<token-expired ref="tokenExpiredModal" />
	</div>
</template>

<script>
import Messages from '@/components/messages.vue';
import TokenExpired from '@/components/General/TokenExpired';
import SettingsPanel from '@/components/General/SettingsPanel.vue';
import AccountSelect from '@/components/General/AccountSelect.vue';
import { transformErrorObject as ErrHandler } from '@/util/error.js';
import EventBus from '@/util/eventbus.js';
import { toLocale } from '@/plugins/filters.js';
import { toDateTimeFormat, accountDisplay } from './plugins/filters';
import favloader from 'favloader';
import { backendUrl } from '@/util/env';
import LayoutMaintenance from '@/components/maintenance.vue';
import warnings from '@/assets/config/warnings.json';


export default {
	name: 'App',

	components: {
		Messages,
		TokenExpired,
		SettingsPanel,
		AccountSelect,
		LayoutMaintenance
	},

	data() {
		return {
			title: this.$t('default.common.admin'),
			items: [],
			lastError: [],
			warnings: [],
			groupedRoutes: {},
			showSidebar: true
		};
	},

	computed: {
		loading() {
			return this.$store.state.base.loading;
		},
		isDemo() {
			return backendUrl.search(/demo|localhost/) > -1;
		},
		navGroups() {
			return this.$store.getters['auth/user/routes/getGroups']();
		},
		canGoBack() {
			return this.$route.path !== '/';
		},
		isProductRoute() {
			const path = this.$route.path;
			return path.startsWith('/product') && path !== '/product/list';
		},
		productTitle() {
			if (!this.isProductRoute) {
				return '';
			}

			let productTitle = this.$store.getters['product/getValue']('basis-f1');

			if (productTitle) {
				let actualTitle = toLocale(productTitle);

				if (this.$route.params.rev && this.$store.state.product.rev) {
					const changeDate = this.$store.state.product.rev.updated;
					actualTitle = `Stand vom ${toDateTimeFormat(changeDate)}: ${actualTitle}`;
				}

				return `${actualTitle}: `;
			}

			return '';
		},
		subTitle() {
			if (!this.isProductRoute) {
				return null;
			}

			const responsibility = this.$store.getters['product/getValue']('_responsible');
			if (responsibility) {
				const institution = this.$store.getters['auth/org/byId'](responsibility.institution);

				if (institution) {
					let result = `${this.$t('default.common.responsibleInstitution')}: ${toLocale(institution.data.title)}`;

					const user = accountDisplay(responsibility.accountId);
					if (user) {
						result += `, ${this.$t('default.common.user')}: ${user}`;
					}

					return result;
				}
			}

			return null;
		},
		isAdmin() {
			return this.$store.getters['auth/user/roles/isAdmin'];
		},
		role() {
			return this.$store.getters['auth/user/roles/getRole']();
		},
		isMaintenance() {
			return this.$store.state.base.maintenanceMode;
		}
	},

	created() {
		EventBus.$on('page.change', this.changeTitle);
		EventBus.$on('error', (err) => {
			let warningKey;
			for (let [key, warningText] of Object.entries(warnings)) {
				if (warningText === err.message) {
					warningKey = key;
				}
			}
			if (warningKey) {
				let warningTranslation = this.$t(`default.common.warnings.${warningKey}`);
				if (!this.warnings.includes(warningTranslation)) this.warnings.push(warningTranslation);
				this.$refs.warningModal.show();
			} else {
				this.lastError = ErrHandler(err);
				this.$refs.errorModal.show();
			}
		});
		EventBus.$on('auth.tokenExpired', () => {
			this.loading = false;
			favloader.stop();
		});
		console.log(this, process.env);
	},

	beforeDestroy() {
		EventBus.$off('page.change', this.changeTitle);
	},

	methods: {
		goBack() {
			this.$router.go(-1);
		},
		changeTitle(page) {
			this.title = page.title;
		},
		mainClass() {
			return {
				'body': true,
				'product-ui': this.$route.path.indexOf('/product') > -1 ? 'product-ui' : ''
			};
		},
		toggleSidebar() {
			this.showSidebar = !this.showSidebar;
		},
		navItems(group) {
			return this.$store.getters['auth/user/routes/getRoutesByGroup'](group);
		},
		hideSettingsPanel() {
			this.$refs.settingsPanel.hide();
		}
	}
};
</script>

<style scoped>
	.app-title .subtitle {
		font-size: .7em;
	}
</style>
