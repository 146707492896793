<template>
	<div class="custom-view-wrapper">
		<h2 class="dg-title">
			{{ t('default.dialog.save') }}
		</h2>

		<div
			v-if="options.html"
			class="dg-content"
			v-html="messageBody"
		/>
		<div v-else class="dg-content">
			{{ messageBody }}
		</div>
		<br>

		<b-button
			@click="handleSave()"
			variant="success"
		>
			{{ t('default.dialog.save') }}
		</b-button>
		<b-button
			@click="handleDismiss()"
			variant="danger"
			class="ml-3"
		>
			{{ t('default.dialog.discard') }}
		</b-button>
		<b-button
			@click="handleCancel()"
			class="ml-3"
		>
			{{ t('default.dialog.cancel') }}
		</b-button>
	</div>
</template>

<script>
import EventBus from '@/util/eventbus.js';
import DialogMixin from 'vuejs-dialog/dist/vuejs-dialog-mixin.min';
import { i18n } from '@/main';

export default {
	name: 'SaveDialog',

	mixins: [DialogMixin],
	methods: {
		async handleSave() {
			if (this.options.newListId) {
				EventBus.$emit('saveProductAndSwitchList', this.options.newListId);
			} else {
				EventBus.$emit('saveProduct');
			}
			this.proceed(); // included in DialogMixin
		},
		handleDismiss() {
			EventBus.$emit('confirmSwitchList', this.options.newListId);
			this.proceed();
		},
		handleCancel() {
			this.cancel(); // included in DialogMixin
		},
		t(key) {
			return i18n.t(key);
		}
	}
};
</script>

<style scoped>

</style>
